import React from 'react';

// External Components
import { Box, Section } from '@thepuzzlers/pieces';

// Local Components
import {
  Vector,
  TextBlockWrapper,
  RegularLink,
  GridSpacer,
  MarkedHeading
} from 'components';

// Assets
import year from 'assets/svg/mdl-module-1996-outlined.svg';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const About = ({
  data: { headline, text_block_one, text_block_two, link_label, link_to }
}) => {
  const textBlocks = [{ text: text_block_one }, { text: text_block_two }];

  return (
    // Markup
    <Section id="landing-page__about">
      <Spacer position={0} />
      <Headline data={headline} />
      <TextBlocks data={textBlocks} />
      <LinkAndYear text={link_label} to={link_to} />
      <Spacer position={1} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <MarkedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['2.4rem', '2.6rem', '3rem', '2.4rem', '2.8rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '1/span 18',
        '2/span 22',
        '4/span 17',
        '5/span 15'
      ],
      lineHeight: 1.25
    }}
  />
);

const TextBlocks = ({ data }) => (
  <TextBlockWrapper
    data={data}
    sx={{
      gap: ['4.8rem', '4.8rem', '6.4rem', '6.7rem', '5rem', '7rem'],
      gridColumn: [
        '1/13',
        '1/span 10',
        '1/span 17',
        '2/span 22',
        '4/span 17',
        '5/span 15'
      ],
      mt: ['4.8rem', '7.2rem', '7rem', '4rem', '5.8rem', '7.2rem']
    }}
    textSx={{
      fontSize: ['1.6rem', '1.6rem', '1.8rem', '1.5rem', '1.5rem', '1.6rem']
    }}
  />
);

const LinkAndYear = ({ text, to }) => (
  <Box
    sx={{
      justifySelf: ['start', 'start', 'start', 'start', 'end', 'start'],
      gridColumn: [
        '1/span 7',
        '1/span 5',
        '1/span 8',
        '2/span 6',
        '3/span 6',
        '4/span 6'
      ],
      m: [
        '3.2rem 0 0',
        '6.6rem 0 0',
        '6.4rem 0 0',
        '1.1rem 0 0',
        '3.4rem 0 0',
        '4.3rem 0 0 2.4rem'
      ],
      position: 'relative',
      width: ['17.4rem', '100%', '100%', '15.3rem', '22.1rem', '25.2rem'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
    <RegularLink
      text={text}
      to={to}
      primary
      sx={{
        position: 'absolute',
        //? width max-content with position absolute is behave weird in the IOS 14.1
        width: 'unset'
      }}
    />
    <Vector className="about__year" src={year} alt="" sx={{ width: '100%' }} />
  </Box>
);

// Spacer

const Spacer = ({ position }) => {
  const heights = [
    ['23.2rem', '25.3rem', '30.1rem', '25.4rem', '30rem', '31.8rem'],
    ['26.4rem', '26.1rem', '23.8rem', '29.5rem', '39rem', '31.9rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
