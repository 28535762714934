import React from 'react';

// External Components
import { Box, fullWidthMinusMargins, Section } from '@thepuzzlers/pieces';

// Local Components
import { GridSpacer, Vector, MarkedHeading } from 'components';

// Assets
import city from 'assets/svg/stackedModule/city.svg';
import modulOne from 'assets/svg/stackedModule/module-02.svg';
import modulTwo from 'assets/svg/stackedModule/module-03.svg';
import modulThree from 'assets/svg/stackedModule/module-01.svg';

// Animations
import { animateModulsInPlace } from './animations';

// Helper Function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const StackedModule = ({ data: { headline } }) => {
  React.useEffect(() => {
    const killAnimation = animateModulsInPlace();

    return killAnimation;
  }, []);

  return (
    // Markup
    <Section id="stacked-module">
      <Spacer />
      <Headline data={headline} />
      <City />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <MarkedHeading
    as="h3"
    data={convertApiHtmlText(data.html)}
    sx={{
      alignSelf: 'start',
      fontSize: ['1.8rem', '2rem', '2.4rem', '1.7rem', '2rem', '2.2rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '9/25',
        '13/span 11',
        '13/span 10',
        '14/span 8'
      ],
      lineHeight: 1.5
    }}
  />
);

const City = () => (
  <Box
    className="stacked-module__city-illustration"
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '2/span 19',
        '2/span 11',
        '3/span 10',
        '5/span 9'
      ],
      gridRow: [3, 3, 3, 2, 3, 2],
      mt: ['13.8rem', '19rem', '17rem', '8.8rem', 0, '14.6rem'],
      mx: [fullWidthMinusMargins[0], fullWidthMinusMargins[1], 0, 0, 0, 0],
      position: 'relative',
      width: 'auto',
      zIndex: 2
    }}>
    <ModulOne />
    <ModulTwo />
    <ModulThree />
    <Vector src={city} alt="" sx={{ width: '100%' }} />
  </Box>
);

const ModulOne = () => (
  <Vector
    className="city-illustration__modul-one"
    alt=""
    src={modulOne}
    sx={{
      position: 'absolute',
      top: '2.3%',
      left: '29%',
      width: '12.2%',
      // Animation value
      transform: [
        'translateY(-59rem)',
        'translateY(-72rem)',
        'translateY(-84rem)',
        'translateY(-30rem)',
        'translateY(-51rem)',
        'translateY(-52rem)'
      ]
    }}
  />
);

const ModulTwo = () => (
  <Vector
    className="city-illustration__modul-two"
    alt=""
    src={modulTwo}
    sx={{
      position: 'absolute',
      top: '15%',
      left: '-7%',
      width: '27%',
      // Animation value
      transform: [
        'translateY(-50rem)',
        'translateY(-66rem)',
        'translateY(-73rem)',
        'translateY(-25rem)',
        'translateY(-40rem)',
        'translateY(-40rem)'
      ]
    }}
  />
);

const ModulThree = () => (
  <Vector
    className="city-illustration__modul-three"
    src={modulThree}
    sx={{
      position: 'absolute',
      right: '17.3%',
      top: '60.8%',
      width: '11%',
      // Animation value
      transform: [
        'translateY(-63rem)',
        'translateY(-87rem)',
        'translateY(-91rem)',
        'translateY(-32rem)',
        'translateY(-52rem)',
        'translateY(-54rem)'
      ]
    }}
  />
);

const Spacer = () => (
  <GridSpacer
    height={['53.7rem', '68.7rem', '76.7rem', '31.2rem', '60.7rem', '57rem']}
  />
);
