import React from 'react';

// External Components
import { Paragraph } from '@thepuzzlers/pieces';

// Helper Function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Description = ({ data, isDark = false }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(data.html)
    }}
    sx={{
      color: isDark ? 'text' : 'background',
      fontFamily: 'primary.normal',
      fontSize: ['1.8rem', '2rem', '2.4rem', '1.7rem', '2rem', '2rem'],
      gridColumn: [
        '1/13',
        '1/13',
        '1/25',
        '4/span 18',
        '5/span 16',
        '7/span 13'
      ],
      lineHeight: 1.65,
      '& > span': {
        color: isDark ? 'text' : 'secondary',
        fontFamily: 'primary.italic'
      }
    }}
  />
);
