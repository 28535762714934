import gsap from 'gsap';

export const removeIntro = () => {
  gsap.set('body', { overflow: 'hidden' }); // don't allow user scroll

  let tl = gsap.timeline({ defaults: { duration: 0.8, ease: 'power2.out' } });

  tl.to('.intro__lime-cover', {
    yPercent: 100,
    delay: 0.3
  }).to('#intro', {
    yPercent: 100,
    onComplete: () => {
      gsap.set('#intro', { display: 'none' });
      gsap.set('body', { overflow: 'visible' }); // allow user scroll when intro animation ends
    }
  });

  return () => {
    tl.pause(0).kill(true);
  };
};
