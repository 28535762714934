import React from 'react';

// External Components
import { Box, Section, Paragraph } from '@thepuzzlers/pieces';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

// Local Components
import {
  GridSpacer,
  ImageContainer,
  Vector,
  UnderlinedHeading,
  ArrowDown,
  Awards,
  AspectRatioImageContainer
} from 'components';

// Animations
import { floatModule, parallaxEffect, arrowAnimation } from './animation';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Header = ({
  staticData: { headline, image, awardImages, kfhLogo },
  data: { description }
}) => {
  const { locale } = usePageContext();

  React.useEffect(() => {
    const killParallax = parallaxEffect();

    return killParallax;
  }, []);

  return (
    // Markup
    <Section as="header" id="landing-page__header">
      <Spacer position={0} />
      {locale === 'de' ? (
        <HeadlineDE data={headline} />
      ) : (
        <HeadlineEN data={headline} />
      )}
      <Description data={description} />
      <KfhLogo data={kfhLogo} />
      <ImageWrapper data={image} />
      <Awards data={awardImages} />
      <Spacer position={1} />
    </Section>
  );
};

const HeadlineDE = ({ data }) => (
  <Headline
    data={data}
    headingSx={{
      fontSize: ['4.2rem', '5.4rem', '7.2rem', '5rem', '7.2rem', '9.8rem'],
      '& > .headline__first-image-spacer': {
        display: [
          'none',
          'inline-block',
          'inline-block',
          'none',
          'none',
          'none'
        ],
        width: [0, '10rem', '18rem', 0, 0, 0]
      },
      '& > .headline__second-image-spacer': {
        display: [
          'inline-block',
          'none',
          'none',
          'inline-block',
          'inline-block',
          'inline-block'
        ],
        width: ['10.4rem', 0, 0, '18rem', '28rem', '30rem']
      }
    }}
    vectorSx={{
      top: [
        '-33.5rem',
        '-48.8rem',
        '-59.5rem',
        '-32.5rem',
        '-54.2rem',
        '-53.5rem'
      ],
      left: ['18.8rem', '34rem', '45rem', '17.5rem', '24rem', '30.5rem']
    }}
  />
);

const HeadlineEN = ({ data }) => (
  <Headline
    data={data}
    headingSx={{
      fontSize: ['4rem', '5.4rem', '7.2rem', '5rem', '6.4rem', '8.2rem'],
      '& > .space-holder': {
        width: [null, null, '38rem', '26rem', null, null],
        display: [
          'none',
          'inline-block',
          'inline-block',
          'inline-block',
          'none',
          'none'
        ]
      },
      '& > .space-holder-desktop': {
        width: [null, null, null, null, '22rem', '22rem'],
        display: [
          'none',
          'none',
          'none',
          'none',
          'inline-block',
          'inline-block'
        ]
      }
    }}
    vectorSx={{
      top: ['-44rem', '-48.8rem', '-59.5rem', '-32.5rem', '-62.2rem', '-65rem'],
      left: ['18.8rem', '36rem', '45rem', '17.5rem', '67rem', '90rem']
    }}
  />
);

const Description = ({ data }) => (
  <Box
    className="landing-page__header__description"
    sx={{
      gridColumn: ['1/13', '5/13', '12/25', '14/25', '17/25', '17/25'],
      gridRow: [null, null, null, null, 2, 2],
      mt: ['4.7rem', '5.2rem', '6rem', '1.9rem', '15rem', '20.5rem'],
      position: 'relative'
    }}>
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(data.html)
      }}
      sx={{
        fontFamily: 'primary.normal',
        fontSize: ['1.6rem', '1.7rem', '1.8rem', '1.4rem', '1.5rem', '1.6rem'],
        lineHeight: 1.6,
        textAlign: 'justify',
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
    />
    <YellowArrowDown />
  </Box>
);

const YellowArrowDown = () => {
  return (
    <ArrowDown
      variants={arrowAnimation}
      initial="initial"
      animate="animate"
      sx={{
        color: 'secondary',
        width: ['2.6rem', '3.1rem', '3.6rem', '2.6rem', '2.6rem', '3.2rem'],
        position: 'absolute',
        top: ['14.5rem', '15.9rem', '15rem', '13rem', '4rem', '2.9rem'],
        left: ['25.2rem', '26.7rem', '28.2rem', '19.9rem', '-8.7rem', '-9.7rem']
      }}
    />
  );
};

const KfhLogo = ({ data }) => (
  <Vector
    className="landing-page__header__kfh-logo"
    src={data.src.publicURL}
    alt={data.alt}
    sx={{
      alignSelf: 'start',
      gridColumnStart: 1,
      gridRow: [null, null, null, 5, 4, 4],
      mt: ['17.5rem', '21.5rem', '19.8rem', '1.2rem', '2.4rem', '3.2rem'],
      width: ['18.9rem', '25.4rem', '31.8rem', '22.9rem', '29.6rem', '29.6rem']
    }}
  />
);

const ImageWrapper = ({ data }) => (
  <AspectRatioImageContainer
    className="landing-page__header__image-wrapper"
    {...data}
    aspectRatios={[1 / 1.24, 1 / 1.24, 1 / 1.24, 1 / 0.58, 1 / 0.58, 1 / 0.58]}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      mt: ['0.8rem', '0.8rem', '1.1rem', '17.5rem', '7rem', '9.7rem'],
      width: '100%',
      zIndex: 2
    }}
  />
);

// Headline elements

const Headline = ({ data: { text, image }, headingSx, vectorSx }) => (
  <Box
    className="landing-page__header__headline"
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '1/25'],
      gridRow: 2,
      position: 'relative'
    }}>
    <UnderlinedHeading
      as="h1"
      data={text}
      sx={{
        alignSelf: 'start',
        fontFamily: 'primary.normal',
        lineHeight: [1.15, 1.15, 1.15, 1, 1, 1],
        textAlign: 'justify',
        textTransform: 'uppercase',
        '& > .landing__header__headline-underline-1::before': {
          animation: '1s ease 2s revealUnderline',
          animationFillMode: 'forwards',
          width: 0
        },
        '& > .landing__header__headline-underline-2::before': {
          animation: '1s ease 2.2s revealUnderline',
          animationFillMode: 'forwards',
          width: 0
        },
        ...headingSx
      }}
      underlineSx={{
        height: '25%',
        bottom: '0.4rem'
      }}
    />
    <HeadlineImg data={image} sx={vectorSx} />
  </Box>
);

const HeadlineImg = ({ data: { src }, sx }) => (
  <Vector
    initial="initial"
    animate="animate"
    variants={floatModule}
    src={src.publicURL}
    alt=""
    sx={{
      position: 'absolute',
      width: ['12rem', '15.6rem', '19.2rem', '10.6rem', '17.2rem', '18.3rem'],
      zIndex: 1,
      ...sx
    }}
  />
);

// Spacer

// We use the spacers inside the section so noise keeps correct pattern
const Spacer = ({ position }) => {
  const heights = [
    ['26.4rem', '45.4rem', '47.3rem', '14.5rem', '48.1rem', '43.2rem'],
    ['25.1rem', '31rem', '18.2rem', '19.2rem', 0, 0] // the last two spacer is left to 0 to support the parallax animation
  ];

  return <GridSpacer height={heights[position]} />;
};
