import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const timberModuleTransition = () => {
  const imageToAnimate = document.querySelector(
    '.timber__images-block__timber'
  );

  const getHeight = (element) => {
    const { height } = element ? element.getBoundingClientRect() : null;

    return height;
  };

  const height = getHeight(imageToAnimate);

  // Pin ImageWrapper
  const scrollTrigger = ScrollTrigger.create({
    trigger: '.timber__images-block',
    start: 'center center',
    endTrigger: '.modular__image-space-holder',
    end: 'center center',
    pin: true,
    pinSpacing: false,
    invalidateOnRefresh: true
  });

  // Shrink first image to showcase the module image
  const tl = gsap
    .timeline({
      scrollTrigger: {
        trigger: '#modular',
        start: `top center+=${height / 2}px`,
        end: `+=${height}px`,
        scrub: true
      }
    })
    .to(imageToAnimate, {
      height: () => '0%',
      ease: 'none'
    });

  return () => {
    scrollTrigger.kill(true);
    tl.scrollTrigger?.kill(true);
    tl.pause(0).kill(true);
  };
};
