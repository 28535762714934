import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import {
  GridSpacer,
  IllustrationBlockWithNoise,
  TealNoiseSection
} from 'components';

// Self Components
import { Description, CaptionWithLink } from '../components';

// Animations
import { timberModuleTransition } from '../animations';

export const Timber = ({
  staticData: { images },
  data: { caption, description, link_label, link_to }
}) => {
  React.useEffect(() => {
    const killAnimation = timberModuleTransition();

    return killAnimation;
  }, []);

  return (
    // Markup
    <ContentWrapper>
      <Description data={description} />
      <Spacer />
      <CaptionAndLink
        caption={caption}
        hyperlink={{ text: link_label, to: link_to }}
        isTimber
      />
      <ImagesBlock {...images} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection
    id="timber"
    sx={{
      pt: ['6.4rem', '6.4rem', 0, '15.9rem', '21.9rem', '21.9rem'],
      pb: ['10.8rem', '24rem', '24rem', '14.2rem', '24rem', '25.8rem'],
      mt: '-2px' // Avoid pixel glitch
    }}>
    {children}
  </TealNoiseSection>
);

const CaptionAndLink = ({ caption, hyperlink }) => (
  <CaptionWithLink
    caption={caption}
    hyperlink={hyperlink}
    scribbleColor="background"
    sx={{
      alignSelf: ['start', 'start', 'start', 'start', 'end', 'end'],
      gridColumn: [
        '1/span 9',
        '1/span 7',
        '1/span 12',
        '4/span 8',
        '1/span 6',
        '3/span 5'
      ],
      gridRow: 3,
      mb: [0, 0, 0, 0, '-1.5rem', '-1.5rem'],
      zIndex: 1
    }}
  />
);

const ImagesBlock = ({ timber, module }) => (
  <Box
    className="timber__images-block"
    sx={{
      borderRadius: 'card',
      boxShadow: 700,
      gridColumn: [
        '2/span 10',
        '3/span 8',
        '6/span 14',
        '8/span 10',
        '8/span 10',
        '9/span 8'
      ],
      overflow: 'hidden',
      mt: ['11rem', '13.7rem', '13rem', '8.5rem', 0, 0],
      position: 'relative',
      zIndex: 4 // Animation value
    }}>
    <Box className="timber__images-block__timber" sx={{ overflow: 'hidden' }}>
      <IllustrationBlockWithNoise
        data={timber}
        bg="secondary"
        sx={{
          minHeight: 'inherit', // Animation value
          position: 'relative',
          zIndex: 2
        }}
      />
    </Box>
    <IllustrationBlockWithNoise
      className="timber__images-block__module"
      data={module}
      bg="primary"
      sx={{
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1
      }}
    />
  </Box>
);

const Spacer = () => (
  <GridSpacer
    height={['26.2rem', '38.8rem', '45.7rem', '13.3rem', '29.1rem', '16.7rem']}
  />
);
