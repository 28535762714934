import React from 'react';

// External Components
import { Box, Heading } from '@thepuzzlers/pieces';

// HelperFunction
import { convertApiHtmlText } from 'graphqlHooks/helper';

// Local Components
import { HyperLink } from 'components';

export const CaptionWithLink = ({
  className = '',
  caption,
  hyperlink,
  darkText,
  darkLinkBg,
  scribbleColor,
  sx,
  ...props
}) => {
  return (
    <Box
      className={`caption-with-link ${className}`}
      sx={{ position: 'relative', ...sx }}
      {...props}>
      <Caption caption={caption} isDark={darkText} />
      <HyperLink
        {...hyperlink}
        isDark={darkLinkBg}
        scribbleColor={scribbleColor}
        primary
        sx={{
          mt: ['3.9rem', '4.6rem', '4rem', '2.8rem', '4.4rem', '3.6rem']
        }}
      />
    </Box>
  );
};

const Caption = ({ caption, isDark }) => (
  <Heading
    as="h3"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(caption.html)
    }}
    sx={{
      color: isDark ? 'text' : 'background',
      fontSize: ['2.4rem', '2.8rem', '3.4rem', '2.4rem', '2.6rem', '2.8rem'],
      fontFamily: 'primary.normal',
      lineHeight: 1.25,
      '& > span': {
        color: isDark ? 'text' : 'secondary',
        fontFamily: 'primary.italic'
      }
    }}
  />
);
