import React from 'react';

// External Components
import { Paragraph, Section } from '@thepuzzlers/pieces';

// Local Components
import { GridSpacer, LeadPhoto, MarkedHeading } from 'components';

// Helper function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Production = ({
  staticData: { portrait },
  data: { caption, description, employee_name, employee_position }
}) => {
  return (
    // Markup
    <Section id="landing-page__production">
      <ProductionLeadPhoto
        staticData={portrait}
        name={employee_name}
        position={employee_position}
      />
      <Spacer position={0} />
      <Caption data={caption} />
      <Description data={description} />
      <Spacer position={1} />
    </Section>
  );
};

const ProductionLeadPhoto = ({ staticData: { image }, name, position }) => {
  return (
    <LeadPhoto
      image={image}
      name={convertApiHtmlText(name.html)}
      position={position}
      sx={{
        gridColumn: [
          '1/span 8',
          '1/span 10',
          '1/span 16',
          '1/span 16',
          '1/span 10',
          '1/span 9'
        ]
      }}
    />
  );
};

const Caption = ({ data }) => (
  <MarkedHeading
    as="h2"
    className="production__caption"
    data={convertApiHtmlText(data.html)}
    sx={{
      fontSize: ['2.2rem', '2.4rem', '3rem', '1.7rem', '2.2rem', '2.4rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '1/span 21',
        '1/span 10',
        '1/span 11',
        '1/span 9'
      ],
      lineHeight: 1.6,
      '& > span': { fontFamily: 'primary.italic' }
    }}
  />
);

const Description = ({ data }) => (
  <Paragraph
    className="production__description"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(data.html)
    }}
    sx={{
      fontFamily: 'primary.normal',
      fontSize: ['1.7rem', '2rem', '2rem', '1.6rem', '1.7rem', '1.8rem'],
      fontFeatureSettings: "'pnum' on, 'onum' on",
      gridColumn: [
        '1/13',
        '1/span 11',
        '1/span 18',
        '13/25',
        '13/25',
        '12/span 12'
      ],
      lineHeight: 1.6,
      mt: ['10.8rem', '10.8rem', '12.4rem', 0, 0, 0],
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);

// Spacers

// We use the spacers inside the section so noise keeps correct pattern
const Spacer = ({ position }) => {
  const heights = [
    ['3.2rem', '3.2rem', '3.3rem', '4.4rem', '4.4rem', '4.5rem'],
    ['16.1rem', '17rem', '15rem', '17.9rem', '18.4rem', '19.5rem']
  ];

  return <GridSpacer height={heights[position]} />;
};
