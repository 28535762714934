import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Local Components
import { GridSpacer, LimeNoiseSection } from 'components';

// Self Components
import { Description, CaptionWithLink } from '../components';

export const Modular = ({
  data: { caption, description, link_label, link_to }
}) => {
  return (
    // Markup
    <ContentWrapper>
      <ImageSpaceHolder />
      <Spacer />
      <Description data={description} isDark />
      <CaptionAndLink
        caption={caption}
        hyperlink={{ text: link_label, to: link_to }}
      />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <LimeNoiseSection
    id="modular"
    sx={{
      pt: ['10.8rem', '24rem', '24rem', '9.6rem', '24rem', '17.8rem'],
      pb: ['24.8rem', '31.6rem', '34.8rem', '13.3rem', '27.4rem', '34.4rem']
    }}>
    {children}
  </LimeNoiseSection>
);

const ImageSpaceHolder = () => (
  <Box
    className="modular__image-space-holder"
    sx={{
      aspectRatio: '1/1',
      gridColumn: [
        '2/span 10',
        '3/span 8',
        '6/span 14',
        '8/span 10',
        '8/span 10',
        '9/span 8'
      ]
    }}
  />
);

const CaptionAndLink = ({ caption, hyperlink }) => (
  <CaptionWithLink
    caption={caption}
    hyperlink={hyperlink}
    darkText
    darkLinkBg
    sx={{
      alignSelf: ['start', 'start', 'start', 'start', 'end', 'end'],
      gridColumn: [
        '1/span 9',
        '1/span 7',
        '1/span 12',
        '16/span 8',
        '19/25',
        '19/25'
      ],
      gridRow: [2, 2, 2, 2, 1, 1],
      m: [
        '13.4rem 0 0',
        '18.5rem 0 0',
        '17.8rem 0 0',
        '9.3rem 0 0',
        '0 0 -1.5rem',
        '0 0 -1.5rem'
      ],
      zIndex: 1
    }}
  />
);

const Spacer = () => (
  <GridSpacer
    height={['18rem', '22.5rem', '19.1rem', '15.3rem', '23.6rem', '28.9rem']}
  />
);
