import { gsap } from 'gsap';

export const animateModulsInPlace = () => {
  const tl = gsap.timeline({
    defaults: {
      y: 0,
      ease: 'none'
    },
    scrollTrigger: {
      trigger: '.city-illustration__modul-one',
      endTrigger: '.stacked-module__city-illustration',
      end: 'center center',
      start: 'top 20%',
      scrub: 0.5
    }
  });

  tl.to('.city-illustration__modul-one', { duration: 1 })
    .to('.city-illustration__modul-two', { duration: 0.7 }, 0)
    .to('.city-illustration__modul-three', { duration: 1 }, 0);

  return () => {
    tl.scrollTrigger?.kill(true);
    tl.pause(0).kill(true);
  };
};
