import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const floatModule = {
  initial: {
    y: 80
  },
  animate: {
    y: 0,
    x: [30, -40, 0, -40, 30],
    transition: {
      delay: 1.5,
      type: 'spring',
      duration: 3,
      x: {
        duration: 12,
        repeat: Infinity,
        repeatType: 'reverse'
      }
    }
  }
};

export const arrowAnimation = {
  initial: {
    scaleY: 1
  },
  animate: {
    scaleY: 1.3,
    transformOrigin: 'top left',
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: 'easeInOut',
      repeatType: 'reverse'
    }
  }
};

export const parallaxEffect = () => {
  // Elements
  const headlineElement = document.querySelector(
    '.landing-page__header__headline'
  );
  const imageWrapper = document.querySelector(
    '.landing-page__header__image-wrapper'
  );

  const descriptionText = document.querySelector(
    '.landing-page__header__description'
  );

  const kfhLogo = document.querySelector('.landing-page__header__kfh-logo');

  const awardsLogo = document.querySelector('.header__header__awards-logos');

  ScrollTrigger.matchMedia({
    '(min-width: 1000px)': () => {
      ScrollTrigger.saveStyles([
        imageWrapper,
        headlineElement,
        descriptionText,
        imageWrapper,
        kfhLogo,
        awardsLogo
      ]);
      const triggerStart =
        imageWrapper.getBoundingClientRect().top +
        document.documentElement.scrollTop;

      // Properties of elements
      const styleOfImageWrapper =
        imageWrapper.currentStyle || window.getComputedStyle(imageWrapper);

      const imageWrapperMarginTop = Number(
        styleOfImageWrapper.marginTop.replace('px', '')
      );

      const yMoveTotal = headlineElement.offsetHeight + imageWrapperMarginTop;

      const yMoveHeadline = (1 / 4) * yMoveTotal;
      const yMoveImage = -(3 / 4) * yMoveTotal;
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: imageWrapper,
          start: `top ${triggerStart}`,
          end: `80% ${triggerStart}`,
          scrub: 0
        }
      });

      tl.to([headlineElement, descriptionText], {
        y: yMoveHeadline
      }).to(
        [imageWrapper, kfhLogo, awardsLogo],
        {
          y: yMoveImage
        },
        '<'
      );

      return () => {
        tl.pause(0).kill(true);
        tl.scrollTrigger?.kill(true);
      };
    }
  });
};
