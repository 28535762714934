import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  useLandingPageData,
  Intro,
  Header,
  Production,
  Statistics,
  Timber,
  Modular,
  StackedModule,
  About
} from 'sections/landingPage';

// External Data
import { useKisLandingPageData } from 'graphqlHooks';

const Home = () => {
  const { seo, header, production, statistics, timber } = useLandingPageData();

  const {
    header: cmsHeader,
    production: cmsProduction,
    statistic: cmsStatistic,
    timber: cmsTimber,
    modular: cmsModular,
    stacked_module: cmsStackedModule,
    about: cmsAbout
  } = useKisLandingPageData();

  // try trigger build
  return (
    <>
      <SEO {...seo} />
      <Intro />
      <Header staticData={header} data={cmsHeader} />
      <Production staticData={production} data={cmsProduction} />
      <Statistics staticData={statistics} data={cmsStatistic} />
      <Timber staticData={timber} data={cmsTimber} />
      <Modular data={cmsModular} />
      <StackedModule data={cmsStackedModule} />
      <About data={cmsAbout} />
    </>
  );
};

export default injectIntl(Home);
