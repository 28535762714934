import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useLandingPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: landingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline {
            text
            image {
              src {
                publicURL
              }
              alt
            }
          }
          image {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
            alt
          }
          awardImages {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
            alt
          }
          kfhLogo {
            src {
              publicURL
            }
            alt
          }
        }
        production {
          portrait {
            image {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
            }
          }
        }
        statistics {
          video {
            text
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
              alt
            }
            noise {
              src {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
        timber {
          images {
            timber {
              src {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            module {
              src {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
      }
      de: deLandingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline {
            text
            image {
              src {
                publicURL
              }
              alt
            }
          }
          image {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
            alt
          }
          awardImages {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
            alt
          }
          kfhLogo {
            src {
              publicURL
            }
            alt
          }
        }
        production {
          portrait {
            image {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
            }
          }
        }
        statistics {
          video {
            text
            image {
              src {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
                }
              }
              alt
            }
            noise {
              src {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
        timber {
          images {
            timber {
              src {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            module {
              src {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
      }
    }
  `);

  return data[locale];
};
