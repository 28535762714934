import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const progressBarPortrait = () => {
  const progressBar = document.querySelector(
    '.statistics__porcentage-bar-portrait'
  );
  const percentageNumber = progressBar.children[0];

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.statistic__description',
      start: 'top center'
    }
  });

  tl.from(progressBar, {
    yPercent: 100,
    duration: 1.2,
    onUpdate: () => {
      if (percentageNumber) {
        const progress = (tl.progress() * 75).toFixed(0);
        percentageNumber.innerHTML = `${progress}%`;
      }
    }
  });

  return () => {
    tl.pause(0).kill(true);
    tl.scrollTrigger?.kill(true);
  };
};

export const progressBarLandscape = () => {
  const progressBar = document.querySelector(
    '.statistics__porcentage-bar-landscape'
  );
  const percentageNumber = progressBar.children[0];

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: progressBar,
      start: 'top 70%'
    }
  });

  tl.from(progressBar, {
    xPercent: -100,
    duration: 1.2,
    onUpdate: () => {
      if (percentageNumber) {
        const progress = (tl.progress() * 75).toFixed(0);
        percentageNumber.innerHTML = `${progress}%`;
      }
    }
  });

  return () => {
    tl.pause(0).kill(true);
    tl.scrollTrigger?.kill(true);
  };
};

export const imageSlide = () => {
  const statisticVideo = document.querySelector('.statistics__video');
  const statisticsNumber = document.querySelector('.statistics__numbers');

  const yMove = statisticVideo.offsetHeight * 0.3; // move only 30 percent of statisticVideo.offsetHeight
  ScrollTrigger.matchMedia({
    '(min-width: 1000px)': function () {
      ScrollTrigger.saveStyles([statisticVideo, statisticsNumber]);

      let tween = gsap.from([statisticVideo, statisticsNumber], {
        y: yMove,
        scrollTrigger: {
          trigger: statisticVideo,
          start: 'top bottom',
          end: 'center center',
          scrub: 0.5
        }
      });

      return () => {
        tween.kill(true);
        tween.scrollTrigger?.kill(true);
      };
    }
  });
};
