import React, { Suspense } from 'react';

// External Components
import { GridWrapper, Box } from '@thepuzzlers/pieces';

// Animations
import { removeIntro } from './animations';

// To do: remove lotties if we are not using them for sure
export const Intro = () => {
  React.useEffect(() => {
    const killAnimation = removeIntro();

    return killAnimation;
  }, []);

  return (
    // Markup
    <ContentWrapper>
      <Cover />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <GridWrapper
    id="intro"
    sx={{
      alignContent: 'center',
      bg: 'background',
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100%',
      zIndex: 1000
    }}>
    {children}
  </GridWrapper>
);

const Cover = () => (
  <Box
    className="intro__lime-cover"
    sx={{
      bg: 'secondary',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%'
    }}
  />
);
